import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import AdSense from 'react-adsense';

import CalculatorInit from '../../scripts/calculatorInit';
import UseMediaQuery from '../../scripts/utilResponsive';

import PageBanner from '../../components/flex/calculators/pageBanner';
import LocationSelect from '../../components/desktop/calculators/LocationSelect';
import UpgradeSelect from '../../components/desktop/calculators/UpgradeSelect';
import BusinessStatsDashboard from '../../components/desktop/calculators/BusinessStatsDashboard';
import LocationSelectMobile from '../../components/mobile/calculators/locationSelectMobile';
import UpgradeSelectMobile from '../../components/mobile/calculators/upgradeSelectMobile';
import BusinessStatsDashboardMobile from '../../components/mobile/calculators/businessStatsDashboardMobile';

import * as ad from '../../styles/ads.module.css';

function forgeryCalculator() {
    // Initialize the calculator
    CalculatorInit('forgery');

    // Determine mobile device or not
    const isBreakpoint = UseMediaQuery(768);

    return (
        <Layout>
            <Seo title="Document Forgery Profit Calculator" description="GTA Online Document Forgery locations, profits, upgrades, and costs all in one spot!" />
            <section className="calculator-background">
                <div>
                    { isBreakpoint ? (
                        <div>
                            <PageBanner/>
                            <div className={ad.Container} id="ad">
                                {/* auto full width responsive ads */}
                                <AdSense.Google
                                    client='ca-pub-5234651621117566'
                                    slot='8655171133'
                                    style={{ display: 'block' }}
                                    format='auto'
                                    responsive='true'
                                />
                            </div>
                            <LocationSelectMobile/>
                            <UpgradeSelectMobile/>
                            <BusinessStatsDashboardMobile/>
                        </div>
                    ) : (
                        <div>
                            <PageBanner/>
                            <div className={ad.Container} id="ad">
                                {/* auto full width responsive ads */}
                                <AdSense.Google
                                    client='ca-pub-5234651621117566'
                                    slot='8655171133'
                                    style={{ display: 'block' }}
                                    format='auto'
                                    responsive='true'
                                />
                            </div>
                            <LocationSelect/>
                            <UpgradeSelect/>
                            <BusinessStatsDashboard/>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default forgeryCalculator;